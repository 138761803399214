import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { AaaidpAnimationImagesQuery } from "../../types/generated";

export const ProjectMetaInfoCPDAnimation: ProjectMetaInfo = {
  name: "cpd-animation",
  displayName: "CPD Animation",
  abbreviation: "CPD Animation",
  subtitle: "Animation produced for CPD",
  // TODO
  description: "Animation produced for Carnet de Passages (CPD) to inform what is an FIA / AIT Carnet De Passages En Douane.",
  categories: [
    Category.design,
  ],
  sectors: [
    Sector.governmentAndNPO,
  ],
  hashtags: [
    Hashtag.animations,
  ],
  hasPage: true,
  keywords: [
    'CPD',
    'Carnet de Passage',
    'animation design',
    'advertisement design',
    'motion graphics',
  ],
}
export const useCPDAnimationInfo = () => {
  const CPDAnimationImages: CpdAnimationImagesQuery = useStaticQuery(graphql`
    query CPDAnimationImages {
      featuredImage: file(relativePath: {eq: "images/cpd-animation/cpd-animation-featured-image.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      pageBackgroundImage: file(relativePath: {eq: "images/cpd-animation/cpd-animation-background.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
    }
  `)
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoCPDAnimation,
    featuredImage: {
      image: CPDAnimationImages.featuredImage!.childImageSharp,
      title: 'CPD Video Preview',
      alt: 'CPD Video Preview Image showing how to drive your car across borders',
    },
    pageBackgroundImage: {
      image: CPDAnimationImages.pageBackgroundImage!.childImageSharp,
      title: 'CPD Video Preview',
      alt: 'CPD Video Preview Image showing how to drive your car across borders',
    },
  }
  return info;
}